<template>
  <v-app>
    <component :is="layout" />
    <alert />
  </v-app>
</template>

<script>
import LayoutDefault from './layouts/Default';
import LayoutLoggedIn from './layouts/LoggedIn';

export default {
  components: {
    'layout-default': LayoutDefault,
    'layout-loggedIn': LayoutLoggedIn,
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
  },
};
</script>

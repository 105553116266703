<template>
  <v-app>
    <v-app-bar dark app color="#1D2C39" flat>
      <v-spacer />
      <!--<v-autocomplete v-if="isSearch" v-model="select" :loading="loading" :items="items2" :search-input.sync="search" cache-items class="mx-4" flat hide-no-data hide-details label="Search" solo-inverted dense></v-autocomplete>-->
      <div v-if="!shouldNotShowSearchBar" style="padding-top: 24px" class="topbar-search">
        <v-text-field
          v-model="searchValue"
          elevation="0"
          outlined
          filled
          dense
          label="Search"
          class="shrink"
          style="width: 425px"
          @keyup.enter="setTopSearch(searchValue)"
        />
      </div>
      <v-toolbar-title v-if="!shouldNotShowSearchBar">
        <v-avatar size="36" style="cursor: pointer" @click="setTopSearch(searchValue)">
          <v-icon color="#FFFFFF"> mdi-magnify </v-icon>
        </v-avatar>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title class="topbar-text pr-3">
        {{ currentTime }}
      </v-toolbar-title>
      <v-toolbar-title class="topbar-text">
        <v-btn-toggle v-model="hourToggle" rounded dense mandatory>
          <v-btn color="primary" class="hourToggle" @click="updateHourStatus(0)"> 12h </v-btn>
          <v-btn color="primary" class="hourToggle" @click="updateHourStatus(1)"> 24h </v-btn>
        </v-btn-toggle>
      </v-toolbar-title>

      <v-toolbar-title style="padding-left: 20px">
        {{ username }}
        <v-menu offset-y>
          <template v-if="userIsAuthenticated" />
        </v-menu>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      color="#1D2C39"
      :mini-variant.sync="mini"
      :permanent="sidebarMenu"
      :width="250"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar rounded="0">
          <img
            src="@/../public/static/logo-sm.png"
            alt="FortifID360 Logo"
            style="cursor: pointer"
            @click.stop="mini = !mini"
          />
        </v-list-item-avatar>
        <v-list-item-title>
          <span style="color: #ffffff">FORTIF</span><span style="color: #006fba">iD 360</span>
        </v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon color="#FFFFFF"> mdi-chevron-left </v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <v-list dense>
        <v-list v-for="(item, index) in activeItems" :key="index" dense dark>
          <v-list-group v-if="item.subLinks" no-action>
            <v-list-item
              slot="activator"
              :to="item.link"
              :href="item.href"
              :target="item.target"
              :disabled="item.disabled"
              @click="eventTrigger(item.click)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="sub in item.subLinks"
              :key="sub.title"
              :to="sub.link"
              :href="sub.href"
              :target="sub.target"
              :disabled="sub.disabled"
            >
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :to="item.link"
            :href="item.href"
            :target="item.target"
            :disabled="item.disabled"
            @click="eventTrigger(item.click)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <keep-alive include="dashboard">
                <router-view :key="$route.fullPath" />
              </keep-alive>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <v-layout row justify-center>
        <v-dialog v-model="loading" persistent fullscreen>
          <v-container fill-height>
            <v-layout row justify-center align-center>
              <v-progress-circular indeterminate :size="70" :width="7" color="black" />
            </v-layout>
          </v-container>
        </v-dialog>
      </v-layout>
      <v-snackbar v-if="releaseInfo" v-model="releaseInfo" timeout="-1">
        {{ releaseInfo }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { store } from '@/store';
import { setPermissions } from '../utils';
import { http } from '@/axios';

export default {
  data() {
    return {
      searchValue: '',
      username: this.getUsername() ? this.getUsername().substring(0, 10) : '',
      sidebarMenu: true,
      items: [
        {
          title: 'Visitor Management',
          link: '',
          icon: 'mdi-post',
          active: true,
          subLinks: [
            {
              title: 'Access Logs',
              link: '/dashboard',
              active: true,
              resource: 'ReadFacilityAccessLogs',
            },
            {
              title: 'Muster',
              link: '/muster',
              active: true,
              resource: 'ReadFacilityAccessLogs',
            },
            {
              title: 'Cardholders',
              link: '/cardholders',
              active: true,
              resource: 'ReadCardholder',
            },
            {
              title: 'Cardholder Groups',
              link: '/cardholder-groups',
              active: true,
              resource: 'ReadCardholderGroup',
            },
            {
              title: 'Timetables',
              link: '/timetables',
              active: true,
              resource: 'ReadTimeTable',
            },
            {
              title: 'Access Control Lists',
              link: '/access-control-lists',
              active: true,
              resource: 'ReadAccessControlList',
            },
            {
              title: 'Escort',
              link: '/escorted-visitors',
              active: true,
              resource: 'ReadVisitorEscort',
            },
          ],
        },
        {
          title: 'Training Management',
          link: '',
          icon: 'mdi-clipboard-text',
          active: true,
          subLinks: [
            {
              title: 'Certifications',
              link: '/tm/certifications',
              active: true,
              resource: 'ReadCertification',
            },
            {
              title: 'Expirations',
              link: '/tm/expirations',
              active: true,
              resource: ['ReadCardholder', 'ReadCertification'],
            },
          ],
        },
        {
          title: 'Facilities',
          link: '/facilities',
          icon: 'mdi-home-city',
          active: 1,
        },
        {
          title: 'Saved Searches',
          link: '/savedsearches',
          icon: 'mdi-magnify',
          active: 1,
        },
        {
          title: 'Users',
          link: '/users',
          icon: 'mdi-account-group',
          active: 1,
        },
        {
          title: 'Support',
          link: '/support',
          icon: 'mdi-frequently-asked-questions',
          active: 1,
        },
        {
          title: 'Devices',
          link: '/devices',
          icon: 'mdi-tablet-cellphone',
          active: 1,
        },
        {
          title: 'Roles',
          link: '',
          icon: 'mdi-account-key',
          active: true,
          subLinks: [
            {
              title: 'Facility',
              link: '/facilitypermissions',
              active: true,
              resource: 'ReadClaimsGroup',
            },
            {
              title: 'System',
              link: '/systempermissions',
              active: true,
              resource: 'ReadClaimsGroup',
              system: true,
            },
          ],
        },
        {
          title: 'Logout',
          click: 'onLogout',
          icon: 'mdi-logout',
          active: 1,
        },
      ],
      currentTime: new Date().toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      selectedLocale: 'EN',
      loading2: false,
      items2: [],
      search: null,
      select: null,
      states: [],
      mini: true,
      releaseInfo: process.env.VUE_APP_HEROKU_BRANCH,
    };
  },
  computed: {
    shouldNotShowSearchBar() {
      return [
        'support',
        'facilitypermissions',
        'systempermissions',
        'cardholderform',
        'cardholders',
        'cardholdergroups',
        'timetables',
        'accesscontrollists',
        'escortedvisitors',
        'visitordetails',
        'certifications',
      ].includes(this.$route.name);
    },
    // searchTerm() {
    //   return this.$store.state.searchTerm;
    // },
    hourToggle: {
      get() {
        // If component is loaded before user is, just default to 1
        if (!this.$store.state.user) {
          return 1;
        }

        // If hour status is already set, don't check API
        if (this.$store.state.hourToggle) {
          return this.$store.state.hourToggle;
        }

        // Otherwise, get the value from API
        this.$store.commit('getHourToggle', { id: this.$store.state.user.id });
        let { hourToggle } = this.$store.state;
        try {
          hourToggle = parseInt(hourToggle);
        } catch (e) {
          return 1;
        }
        return hourToggle;
      },
      set(val) {
        // Note: This is getting called automatically somewhere in load, which will overwrite the value. Move to onClick function?
        // console.log('Setting toggle status to: ' + val);
        // this.$store.commit("setHourToggle", {id: this.$store.state.user.id, hourToggle: val});
      },
    },
    userIsAuthenticated() {
      // this.$store.state.user !== null && this.$store.state.user !== undefined
      return !!this.$store.state.user;
    },
    isSearch() {
      return this.$route.name === 'savedsearches' || this.$route.name === 'dashboard';
    },
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.getters.loading;
    },
    networkError() {
      return this.$store.state.networkError;
    },
    activeItems() {
      return this.items.filter(val => {
        if (val.subLinks) {
          const subs = val.subLinks.filter(item => (item.active == 1 ? item : null));
          val.subLinks = subs;
        }
        return val.active == 1;
      });
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    $route(to, from) {
      this.searchValue = '';
    },
    searchValue(val) {
      this.searchValue = val;
      const d = JSON.parse(localStorage.session);
      d.topSearchDashboard = val;
      this.$store.commit('setSearchTerm', val);
      localStorage.setItem('session', JSON.stringify(d));
    },
  },
  async created() {
    const features = (await http.get('/api/v2/features')).data;

    await this.updateItemsWithPermissions(this.items);
    await this.updateItemsByEnabledFeatures(this.items, features);
  },
  async mounted() {
    this.$root.$on('cleared from dashboard', arg1 => {
      this.clearSearch();
    });

    this.$root.$on('set search from dash', arg1 => {
      this.setSearchFromDash(arg1);
    });
  },
  destroy() {
    clearInterval(this.timerId);
  },
  methods: {
    // traverse the nav items and add a disabled property
    async updateItemsWithPermissions(items) {
      await setPermissions();
      items.forEach(item => {
        if (item.resource) {
          // Check if the resource is an array of resources e.g. ['ReadCardholder', 'ReadFacilityAccessLogs']
          if (Array.isArray(item.resource)) {
            const permissionPromises = item.resource.map(resource => this.getPermission(resource));

            // Use Promise.all to wait for all promises to resolve
            Promise.all(permissionPromises)
              .then(results => {
                // Check if all permissions are true
                const allPermissionsGranted = results.every(res => res);

                // Set the disabled value based on the permissions
                item.disabled = !allPermissionsGranted;
              })
              .catch(error => {
                item.disabled = true; // Default to disabled in case of error
              });
          } else {
            // check resource for permission
            this.getPermission(item.resource).then(res => {
              // disabled value is the inverse of the permission
              item.disabled = !res;
            });
          }
        }
        // recursively update items with sublinks
        if (item.subLinks) {
          this.updateItemsWithPermissions(item.subLinks);
          item.subLinks.forEach(subItem => {
            if (subItem.system && !store.state.layoutPermissions.SystemClaimGroup) {
              subItem.active = false;
            }
          });
        }
      });
    },
    async updateItemsByEnabledFeatures(items, features) {
      items.forEach(item => {
        if (item.title === 'Training Management' && !features.trainingManagement) {
          item.active = features.trainingManagement;
        }
        if (item.subLinks) {
          item.subLinks.forEach(subItem => {
            if (subItem.title === 'Muster' && !features.onSite) {
              subItem.active = features.onSite;
            }
            if (subItem.title === 'Cardholders' && !features.cardholderEnrollment) {
              subItem.active = features.cardholderEnrollment;
            }
            if (subItem.title === 'Cardholder Groups' && !features.cardholderEnrollment) {
              subItem.active = features.cardholderEnrollment;
            }
            if (subItem.title === 'Timetables' && !features.cardholderEnrollment) {
              subItem.active = features.cardholderEnrollment;
            }
            if (subItem.title === 'Access Control Lists' && !features.cardholderEnrollment) {
              subItem.active = features.cardholderEnrollment;
            }
            if (subItem.title === 'Escort' && !features.visitorEscort) {
              subItem.active = features.visitorEscort;
            }
          });
        }
      });
    },
    updateHourStatus(val) {
      this.hourToggle = val;
      this.$store.commit('setHourToggle', {
        id: this.$store.state.user.id,
        hourToggle: val,
      });
      if (this.$route.name === 'timetables') {
        this.$root.$emit('setHourToggle', val);
      }
    },
    setSearchFromDash(val) {
      this.searchValue = val;
      this.$store.commit('setSearchTerm', val);
      this.setTopSearch(val);
    },
    clearSearch() {
      // console.log('cleared')
      this.searchValue = '';
      this.$store.commit('setSearchTerm', '');
      this.setTopSearch('');
    },
    submitSearch() {
      if (this.$route.name === 'adminfacilities') {
        this.$root.$emit('admin facility search', 'admin facility search');
      } else if (this.$route.name === 'devices') {
        this.$root.$emit('device search', 'device search');
      } else if (this.$route.name === 'users') {
        this.$root.$emit('user search', 'user search');
      } else if (this.$route.name === 'admindashboard') {
        this.$root.$emit('searched from admin dash', 'searched from admin dash');
      } else if (this.$route.name === 'dashboard') {
        this.$root.$emit('searched from dash', 'searched from dash');
      } else if (this.$route.name === 'Cardholders') {
        this.$root.$emit('cardholder search', 'cardholder search');
      } else {
        this.$root.$emit('searched from admin', 'searched from admin');
      }
    },
    setTopSearch(val) {
      const d = JSON.parse(localStorage.session);

      d.topSearchDashboard = val;
      this.$store.commit('setSearchTerm', val);

      localStorage.setItem('session', JSON.stringify(d));
      this.submitSearch();
    },
    getUsername() {
      const d = JSON.parse(localStorage.session);
      return d.username;
    },
    async getPermission(resource) {
      const storePermissions = store.state.layoutPermissions;
      if (
        (storePermissions && storePermissions[resource] === 1) ||
        (store.state.permissionsByFacility[resource] &&
          store.state.permissionsByFacility[resource].CardholderGroupIds &&
          store.state.permissionsByFacility[resource].CardholderGroupIds.length > 0) ||
        (store.state.permissionsByFacility[resource] &&
          store.state.permissionsByFacility[resource].AccessControlListIds &&
          store.state.permissionsByFacility[resource].AccessControlListIds.length > 0)
      ) {
        return true;
      }
      return false;
    },
    querySelections(v) {
      this.loading2 = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items2 = this.states.filter(
          e => (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        );
        this.loading = false;
      }, 500);
    },
    // This will always return true since user groups are not set (2021-06-21)
    showMenuItem(groups) {
      if (!groups) {
        return true;
      }
    },
    onLogout() {
      this.$store.dispatch('logout', { user: this.$store.state.user });
    },
    eventTrigger(name) {
      if (name && name != '') {
        this[name]();
      }
    },
  },
};
</script>

<style>
.v-list-group__header__append-icon {
  min-width: 0 !important;
}

button.hourToggle.primary {
  background-color: #ffffff !important;
  color: #006fba !important;
}

button.hourToggle.primary.v-btn--active {
  background-color: #006fba !important;
  border-color: #006fba !important;
  color: #ffffff !important;
}

.topbar-text {
  font-size: 1rem;
}

.tiny {
  font-size: 0.7rem;
}

.container .nopadd {
  padding: 0 0;
  padding-left: 1rem;
}

footer.theme--light.v-footer {
  background-color: #1d2c39;
  color: #ffffff;
}

div.no-gutters .footerlinks-wrapper {
  padding-top: 0.7rem;
}

.v-application a.footerlinks {
  color: #ffffff;
  text-decoration: underline;
  font-size: 0.75rem;
}

.v-application a.footerlinks:hover {
  color: #ffffff;
}

.v-btn--contained::after {
  box-shadow: 0;
}

.v-list-item.v-list-group__header {
  padding-left: 0;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}

/* Hacks to fix css frm vue componet that was breaking the top toolbar for datagrids */

.row {
  margin: 0 !important;
}

.container.no-padd {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-toolbar__content {
  max-width: auto !important;
  margin-right: auto;
  margin-left: auto;
}

/* end hacks */

@media (max-width: 960px) {
  .topbar-text {
    display: none;
  }

  .topbar-search {
    display: none;
  }
}
/* mac hack to fix overlay issue */
.v-overlay {
  align-items: center;
  border-radius: inherit;
  display: none !important;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: none !important;
}

.theme--dark.v-overlay {
  color: transparent !important;
}
/* mac hack to fix overlay issue END */
</style>

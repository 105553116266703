<template>
  <v-autocomplete
    v-model="model"
    solo
    flat
    clearable
    :items="allFacilities"
    item-text="Name"
    item-value="id"
    label="Select a Facility"
    hide-details
    prepend-inner-icon="mdi-magnify"
    placeholder="Start typing to search facilities..."
    @change="id => $emit('setFacility', id)"
  />
</template>

<script>
export default {
  name: 'FacilitySearch',
  props: ['allFacilities'],
  data: () => ({
    model: null,
  }),
  watch: {
    allFacilities(newVal) {
      if (newVal.length === 1) {
        this.model = newVal[0].id;
        this.$store.commit('changeActiveFacility', newVal[0].id);
        this.$emit('setFacility', newVal[0].id);
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

import moment from 'moment';
import { store } from '@/store';
import { http } from '@/axios';

const constructLayoutAccessControl = async allPermissions => {
  // Remove items that do not have a FacilityId
  const allPermissionsClean = removeObjectsWithoutFacilityId(allPermissions);
  if (allPermissionsClean && allPermissionsClean[0]) {
    const keys = Object.keys(allPermissionsClean[0]);
    const result = {};

    keys.forEach(key => {
      result[key] = Math.max(...allPermissionsClean.map(obj => obj[key]));
    });

    // Delete irrelevant keys, since the return is claims and boolean values
    delete result.FacilityId;
    delete result.PrimaryId;
    delete result.Name;
    delete result.Description;
    delete result.Id;

    store.commit('setLayoutPermissions', result);
    return result;
  }
  return false;
};

function removeObjectsWithoutFacilityId(inputArray) {
  return inputArray.filter(obj => 'FacilityId' in obj);
}

export async function setPermissions() {
  try {
    const response = await http.get('/api/v2/permissions');
    store.commit('setPermissionsByFacility', response.data.facilityAccessControl);
    return await constructLayoutAccessControl(response.data.permissions);
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getFeatures() {
  try {
    const features = (await http.get('/api/v2/features')).data;
    return features;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export function nonNullItem(val) {
  if (
    typeof val === 'undefined' ||
    val == null ||
    (typeof val === 'object' && Object.keys(val).length === 0)
  ) {
    return '';
  }
  return val;
}

export function nonNullDate(val, isExpirationDate) {
  if (val == 'undefined' || val == null) {
    return '';
  }
  const date = moment(val);
  if (date.isValid()) {
    if (isExpirationDate) {
      return moment(val).format('MM/DD/YYYY');
    }

    if (store.state.hourToggle === 1) {
      return moment(val).format('MM/DD/YYYY HH:mm');
    }
    return moment(val).format('MM/DD/YYYY hh:mm A');
  }
  return '';
}

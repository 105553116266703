import Vue from 'vue';
import VueRx from 'vue-rx';
import {
  init as sentryVueInit,
  BrowserTracing,
  Replay,
  vueRouterInstrumentation,
} from '@sentry/vue';
import { init as sentryElectronInit } from '@sentry/electron/renderer';
import VueCrudX from 'ext-lib/webpacked/VueCrudX';
import VueCrudXDashboard from 'ext-lib/webpacked/VueCrudXDashboard';
import VueCrudXMuster from 'ext-lib/webpacked/VueCrudXMuster';
import TimePicker from 'ext-lib/webpacked/TimePicker.vue';
import DatePicker from 'ext-lib/webpacked/DatePicker.vue';
import FileUpload from 'ext-lib/webpacked/FileUpload.vue';
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify';
import vuetify from './plugins/vuetify';
import App from './App';
import router from './router';
import { store } from './store';
import i18n from './i18n';
import EventBus from './event-bus';
import Alert from './components/Alert.vue';

import {
  APP_VERSION,
  API_URL,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_TRACERATE,
  SENTRY_REPLAYRATE,
  SENTRY_ERR_REPLAYRATE,
} from '@/config';

Vue.prototype.$EventBus = EventBus;
Vue.component('Alert', Alert);

Vue.config.productionTip = false;
Vue.use(VueRx);
Vue.use(VueColumnsResizableVuetify);

Vue.component('AppDatePicker', DatePicker);
Vue.component('AppTimePicker', TimePicker);
Vue.component('AppFileUpload', FileUpload);
Vue.component('VueCrudX', VueCrudX);
Vue.component('VueCrudXDashboard', VueCrudXDashboard);
Vue.component('VueCrudXMuster', VueCrudXMuster);

if (SENTRY_DSN) {
  const sentryCfg = {
    Vue,
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment: SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
      }),
      new Replay(),
    ],
    tracesSampleRate: SENTRY_TRACERATE,
    tracePropagationTargets: ['localhost', API_URL],
    replaysSessionSampleRate: SENTRY_REPLAYRATE,
    replaysOnErrorSampleRate: SENTRY_ERR_REPLAYRATE,
  };

  if (window && window.electronAPI && typeof window.electronAPI !== 'undefined') {
    sentryElectronInit(sentryCfg, sentryVueInit);
  } else {
    sentryVueInit(sentryCfg);
  }
}

/* eslint-disable no-new */
export const app = new Vue({
  el: '#app',
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App),
});

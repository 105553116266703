<template>
  <div v-if="ready">
    <ConfirmDialogue ref="confirmDialogue"/>
    <!-- progress overlay -->
    <slot name="progress" :vcx="_self">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </slot>
    <!-- filter & table -->
    <component :is="'div'" v-show="!showForm">
      <slot name="table-toolbar" :vcx="_self" v-if="showToolbar">
        <v-toolbar
          v-bind="vtoolbar"
          color="transparent"
          elevation="0"
          class="rounded-t-lg"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <FacilitySearch @setFacility="(f) => setActiveFacility(f)" :allFacilities="allFacilities" ref="facilitySearch"/>
            </v-col>
          </v-row>
          <v-btn title="Show/Hide Filters" dark v-if="filters" v-bind="vbtn.filter.props" @click="showFilter = !showFilter">
            <v-icon>{{
              showFilter ? vbtn.filter.icon2 : vbtn.filter.icon
              }}</v-icon><span>{{ vbtn.filter.label }}</span>
          </v-btn>

          <template>
            <div class="text-center">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon rounded>
                    <v-icon dark title="Edit Columns" depressed v-bind="attrs" v-on="on">edit</v-icon><span></span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline primary">
                    Edit Columns
                  </v-card-title>
                  <v-card-text style="padding-top:10px;padding-bottom:10px;">
                    You can activate columns by checking the desired headers
                    below. You can rearrange columns by dragging-and-droppping
                    the headers below.
                  </v-card-text>
                  <draggable v-model="vtable.headers" @update="updateHeaders">
                    <template v-for="(item, key) in vtable.headers">
                      <v-btn elevation="0" rounded class="search-toolbuttons popup" :class="[item.active ? 'active' : '']" :key="item.value" :name="item.value" @click="setActiveCols(key, item)">
                        {{ item.text }}
                      </v-btn>
                    </template>
                  </draggable>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                      Close Window
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <v-btn dark v-if="crud.create" v-bind="vbtn.create.props" @click.stop="inline.create ? _inlineCreate() : formOpen(null)" :disabled="loading" title="Create Record">
            <v-icon>{{ vbtn.create.icon }}</v-icon><span>{{ vbtn.create.label }}</span>
          </v-btn>
          <v-btn title="Export Data as PDF" dark v-if="crud.export" v-bind="vbtn.export.props" @click.stop.prevent="onExport" :disabled="loading">
            <v-icon>{{ vbtn.export.icon }}</v-icon><span>{{ vbtn.export.label }}</span>
          </v-btn>
          <v-btn dark v-bind="vbtn.reload.props" @click="onFilter" :disabled="!validFilter || loading" title="Reload Data">
            <v-icon>{{ vbtn.reload.icon }}</v-icon><span>{{ vbtn.reload.label }}</span>
          </v-btn>
          <v-btn dark icon rounded :class="{ customloader: loading2 }" @click="clickAutoReload" title="Auto Refresh Data">
            <v-icon dark depressed :loading="loading2" :disabled="loading2">sync</v-icon><span></span>
          </v-btn>
        </v-toolbar>
      </slot>
      <!-- toolbar filters -->
      <div v-if="showFilter">
        <slot name="filter" :filters="filters" :parentId="parentId" :vcx="_self">
          <v-form v-if="filters" v-model="validFilter" ref="searchForm" v-bind="vformFilter" style="padding:0 !important;">
            <v-container fluid no-gutters style="" class="no-padd mt-3 rounded-b rounded-t">
              <v-layout row wrap>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card flat color="#006FBA" class="rounded-0">
                      <v-card-actions style="background-color:#ffffff">
                        <v-layout row wrap>
                          <v-container class="px-0 mr-0">
                            <v-row dense>
                              <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
                                <v-row no-gutters>
                                  <v-btn-toggle v-model="toggle_exceptionStatus" rounded dense mandatory >
                                    <v-btn color="primary" class="searchlinks" value="All">
                                      ALL
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks" value="None">
                                      NONE
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks" value="Handled">
                                      HANDLED
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks" value="Unhandled">
                                      UNHANDLED
                                    </v-btn>
                                  </v-btn-toggle>
                                </v-row>
                              </v-col>
                              <!-- Section 3 -->
                              <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto" class="dateToggle">
                                <v-row no-gutters>
                                  <v-btn-toggle v-model="toggle_range" rounded dense mandatory>
                                    <v-btn color="primary" class="searchlinks">
                                      Week
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks">
                                      Month
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks">
                                      Quarter
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks">
                                      Year
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks" @click.stop="showScheduleForm = true">
                                      Range
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks">
                                      Now
                                    </v-btn>
                                    <v-btn color="primary" class="searchlinks" @click.stop="showDayScheduleForm = true">
                                      Day
                                    </v-btn>
                                  </v-btn-toggle>
                                  <v-dialog v-model="showScheduleForm" max-width="700px">
                                    <v-card>
                                      <v-card-title class="headline primary">
                                        Date Range
                                      </v-card-title>
                                      <v-container style="">
                                        <v-row class="justify-center">
                                          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                            <div style="margin: auto 0;">
                                              <div class="subheading">
                                                Start Date
                                              </div>
                                              <v-date-picker v-model="picker"></v-date-picker>
                                            </div>
                                          </v-col>
                                          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                            <div class="subheading">
                                              End Date
                                            </div>
                                            <v-date-picker v-model="picker2"></v-date-picker>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                      <v-divider></v-divider>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="showScheduleForm = false">
                                          Close Window
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                  <v-dialog v-model="showDayScheduleForm" max-width="700px">
                                    <v-card>
                                      <v-card-title class="headline primary">
                                        Select a single date
                                      </v-card-title>
                                      <v-container style="">
                                        <v-row class="justify-center">
                                          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                            <div style="margin: auto 0;">
                                              <div class="subheading">
                                                Date
                                              </div>
                                              <v-date-picker v-model="singleDayPicker"></v-date-picker>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                      <v-divider></v-divider>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="showDayScheduleForm = false">
                                          Close Window
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-row>
                              </v-col>
                              <!-- Section 4 -->
                              <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto" class="toggleFacilities">
                                <v-row class="justify-center" no-gutters>
                                  <v-select :items="facilityTerminals" item-text="text" item-value="val" v-model="selectedTerminal" outlined dense placeholder="Select a terminal">
                                  </v-select>
                                </v-row>
                              </v-col>
                              <!-- Section 5 -->
                              <v-col cols="6" xl="auto" lg="auto" md="auto" sm="auto" class="pl-0 searchReset justify-right" no-gutters>
                                <v-row class="justify-right">
                                  <v-btn elevation="0" color="#DBDBDB" class="cancel-button d-inline" @click="cancelSearch">
                                    Reset
                                  </v-btn>
                                  <!-- </v-row> -->
                                  <!-- <v-row class="justify-center"> -->
                                  <v-btn elevation="0" dark class="search-button d-inline" color="#39B54A" @click="submitSearch" ref="submitSearchButton">
                                    Search
                                  </v-btn>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-layout>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-layout>
            </v-container>
          </v-form>
        </slot>
      </div>
      <v-card flat color="#006FBA" class="" v-bind:style="[
          showResults ? { 'margin-top': '1rem' } : { 'margin-top': '0rem' }
        ]">
        <v-card-actions style="background-color:#ffffff">
          <v-layout row wrap>
            <v-container style="max-width:100%;">
              <!-- Datagrid Showing -->
              <v-row dense no-gutters v-if="showResults">
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <p class="datagrid-status">
                    Showing <span class="bold">{{ totalRecords }}</span> results
                    from:
                    <span class="bold">{{ totalRecordsTextRange }}</span>
                  </p>
                </v-col>
              </v-row>
              <!-- Datagrid -->
              <v-row dense no-gutters>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12" v-bind:class="[
                    isFailed ? failedClass : '',
                    isPassed ? passClass : ''
                  ]">
                  <slot name="table" :records="records" :totalRecords="totalRecords" :pagination="pagination" :vcx="_self">
                    <v-data-table :headers="activeCols" :items="records" :server-items-length="totalRecords" :options.sync="pagination" v-bind="vtable" :item-key="idName" v-columns-resizable>
                      <template v-slot:item="{ item }">
                        <tr @click="openModal(item)" :key="item[idName]" :ref="`row-${item[idName]}`" style="cursor: pointer">
                          <slot name="td" :headers="activeCols" :item="item" :vcx="_self">
                            <td :key="header.value + index" v-for="(header, index) in activeCols" :class="header.class">
                              <span v-html="
                                  header.render
                                    ? header.render(item[header.value] || '')
                                    : item[header.value]
                                " :title="
                                  header.render
                                    ? header.render(item[header.value] || '')
                                    : item[header.value]
                                "></span>
                            </td>
                          </slot>
                        </tr>
                      </template>
                      <!-- infinite scroll handling -->
                      <template v-if="infinite" v-slot:footer="">
                        <div v-bind="vbtn.more.wrapper" class="more-wrapper">
                          <a class="green-link" v-if="cursor" @click="getRecords({ mode: 'load-more' })" :disabled="loading" v-bind="vbtn.more.props">{{ $t ? $t("vueCrudX.more") : vbtn.more.label }}</a>
                        </div>
                      </template>
                      <!-- no data display -->
                      <template v-slot:no-data>
                        <h3 :value="!loading && !records.length">
                          {{ $t ? $t("vueCrudX.noData") : "NO DATA" }}
                        </h3>
                      </template>
                    </v-data-table>
                  </slot>
                </v-col>
              </v-row>
            </v-container>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-container>
        <exceptionsModal ref="exceptionsModal"/>
      </v-container>
    </component>
  </div>
</template>
<script>
import { http } from "@/axios";
import { mapGetters } from "vuex";
import moment from "moment";
import draggable from "vuedraggable";
import router from "../../src/router";
import exceptionsModal from "../../src/components/Muster/ExceptionsModal"
var Crypto = require("crypto-js");
import FacilitySearch from "../../src/components/Facilities/FacilitySearch.vue"
import ConfirmDialogue from "../../src/components/ConfirmDialogue.vue";

export default {
  name: 'VueCrudXMuster',
  props: {
    parentId: { type: String, default: null },
    refreshMs: { type: Number, default: 0 }
  },
  components: {
    draggable,
    exceptionsModal,
    FacilitySearch,
    ConfirmDialogue
  },
  data() {
    return {
      allFacilities: [],
      facilityTerminals: ['All'],
      searchText: "",
      myIcon: "save",
      saveLoader: null,
      saveLoading: false,
      intervalid1: null,
      loader: null,
      loading2: false,
      autoReload: 0,
      picker: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      picker2: moment().format("YYYY-MM-DD"),
      singleDayPicker:  '',
      showScheduleForm: false,
      showDayScheduleForm: false,
      settings: [],
      dialogm1: "",
      dialog: false,
      savedDataActive: {},
      selectedData: {},
      selected: [],
      selectedFacility: 0,
      selectedTerminal: 'All',
      facilitynames: {},
      failedClass: "failed-status",
      passClass: "pass-status",
      isFailed: false,
      isPassed: false,
      isNo: false,
      isYes: false,
      mobile: window.innerWidth <= 1264,
      toggle_facilities: 0,
      toggle_range: 3,
      toggle_exceptionStatus: 0, 
      switch2: false,
      switch3: false,
      // Private Properties Do Not Override - Start
      ready: false, // TODELETE May Not Be Needed Anymore...
      loading: false,
      records: [],
      totalRecords: 0,
      totalRecordsTextRange: "Last 365 days",
      totalRecordsFacilityName: "ALL",
      showFilter: true, // show/hide
      showForm: false,
      validFilter: true, // form validation
      validForm: true,
      editingRow: null, // for row editing... null or row object
      cursor: "", // infinite scroll cursor
      selectedId: null, //  selected record Id
      // Private Properties Do Not Override - End

      // VARIATION - Start Vuetify 2
      vbtn: {
        // v-btn Component
        back: {
          icon: "reply",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        filter: {
          icon: "keyboard_arrow_down",
          label: "",
          icon2: "keyboard_arrow_up",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        reload: {
          icon: "replay",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        create: {
          icon: "add",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        export: {
          icon: "get_app",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        close: {
          icon: "close",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        delete: {
          icon: "delete",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        update: {
          icon: "save",
          label: "",
          props: { dark: false, light: true, icon: true, fab: false }
        },
        more: {
          icon: "",
          label: "Load More",
          props: null,
          wrapper: {
            style: {
              display: "flex",
            }
          }
        }
      },
      vicon: {
        // v-icon Component
        edit: { name: "edit", props: { small: true, class: "mr-1" } },
        save: { name: "save", props: { small: true, class: "mr-1" } },
        cancel: { name: "cancel", props: { small: true, class: "mr-1" } },
        delete: { name: "delete", props: { small: true, class: "mr-1" } }
      },
      vtoolbar: { height: 60, dark: false, light: true, color: "grey" }, // v-toolbar Component
      vformFilter: {
        class: "grey lighten-3 pa-2",
        style: { overflow: "auto" },
        "lazy-validation": true
      }, // v-form Component for filter
      vformCrud: false, // v-form Component for CRUD
      vtable: {
        // props
        headers: [],
        "footer-props": {
          "items-per-page-options": [10, 20, 50, 100]
        },
        dense: true,
        "multi-sort": false,
        "fixed-header": true,
        dark: false,
        light: true,
        // 'rows-per-page-items': [],
        "hide-default-header": false
      },
      // VARIATION - End Vuetify2

      // depends on UI Framework
      pageSize: 20,
      pageSizeOptions: [10, 20, 50, 100],
      pagination: {
        // VARIATION - Start Vuetify2
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: []
        // totalItems: 0 // completely useless at the moment
        // VARIATION - End Vuetify2
      },
      sorters: {
        // Not Used In Vuetify
      },
      pageDefaults: {
        // page options
        // VARIATION - Start Vuetify2
        start: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: []
        // VARIATION - End Vuetify2
      },
      sortDefaults: {
        // Not Used In Vuetify
      },

      idName: "id",
      infinite: false, // either paged or infinite scroll
      inline: { create: false, update: false, delete: false }, // inline functionality
      title: "",

      filters: null,
      form: null,
      crud: {
        create: null, // operations
        update: null,
        delete: null,
        find: () => ({ status: 500, error: "" }),
        findOne: () => ({ status: 500, error: "" }),
        export: null
      }
    };
  },

  async created() {
    this.ready = true;

    this.idName = this.$attrs.idName || "id";
    this.infinite = !!this.$attrs.infinite; // default false
    this.title = this.$attrs.title || "Title";

    // VARIATION Start Vuetify2
    this.vicon = Object.assign(this.vicon, this.$attrs.vicon || {});
    this.vbtn = Object.assign(this.vbtn, this.$attrs.vbtn || {});
    this.vformFilter = Object.assign(
      this.vformFilter,
      this.$attrs.vformFilter || {}
    );
    this.vformCrud = Object.assign(this.vformCrud, this.$attrs.vformCrud || {});
    this.vtoolbar = Object.assign(this.vtoolbar, this.$attrs.vtoolbar || {});
    this.vtable = Object.assign(this.vtable, this.$attrs.vtable || {});

    this.showToolbar = !!this.$attrs.showToolbar;
    this.showFilter = !!this.$attrs.showFilter;
    this.showResults = !!this.$attrs.showResults;

    if (this.$attrs.pageSizeOptions && this.$attrs.pageSizeOptions.length) {
      this.vtable["footer-props"][
        "items-per-page-options"
      ] = this.$attrs.pageSizeOptions;
    }
    this.sorters = Object.assign(this.sorters, this.$attrs.sorters || {});
    this.sortDefaults = Object.assign(
      this.sortDefaults,
      this.$attrs.sortDefaults || {}
    );
    this.pageDefaults = Object.assign(
      this.pageDefaults,
      this.$attrs.pageDefaults || {}
    );
    this.pageDefaults.itemsPerPage =
      Number(this.$attrs.pageSize) || this.pageSize;
    this.pagination = {
      ...this.pagination,
      page: this.pageDefaults.start,
      itemsPerPage: this.pageDefaults.itemsPerPage,
      sortBy: this.pageDefaults.sortBy,
      sortDesc: this.pageDefaults.sortDesc
    };
    if (this.infinite) this.cursor = this.pageDefaults.start;
    // VARIATION End Vuetify2

    this.crud = Object.assign(this.crud, this.$attrs.crud || {});
    this.filters = this.$attrs.filters || null;
    this.inline = Object.assign(this.inline, this.$attrs.inline || {});
    this.form = this.$attrs.form || null;

    // non-ui reactive data - START
    this.onRowClick =
      this.$attrs.onRowClick ||
      ((item, event, vcx) => {
        // open form on row click? default true
      });
    this.created =
      this.$attrs.created ||
      (async ({ data }) => {
        // do not handle realtime updates of create
        this.filters = this.$attrs.filters || null;
        this.pagination.page = this.pageDefaults.start;
        this.pagination.sortBy = this.pageDefaults.sortBy;
        this.pagination.sortDesc = this.pageDefaults.sortDesc;
        await this.getRecords({ mode: "created" });
      });
    this.deleted =
      this.$attrs.deleted ||
      (async id => {
        // do not handle realtime updates of delete
        this.filters = this.$attrs.filters || null;
        this.pagination.page = this.pageDefaults.start;
        this.pagination.sortBy = this.pageDefaults.sortBy;
        this.pagination.sortDesc = this.pageDefaults.sortDesc;
        await this.getRecords({ mode: "deleted" });
      });
    this.updated =
      this.$attrs.updated ||
      (({ data }) => {
        // also handles real-time updates
        const idx = this.records.findIndex(
          rec => rec[this.idName] === data[this.idName]
        );
        if (idx !== -1) {
          for (let key in this.records[idx]) {
            if (key !== this.idName && data[key])
              this.records[idx][key] = data[key];
          }
        }
      });
    // notifications
    this.notifyCreate =
      this.$attrs.notifyCreate ||
      (({ status, error }) =>
        status === 200 || status === 201
          ? this.$EventBus.$emit('show-alert', {
            message: 'Create OK',
            type: 'success'
          })
          : this.$EventBus.$emit('show-alert', {
            message: "Create Error " + error.toString(),
            type: 'error',
          }))
    this.notifyUpdate =
      this.$attrs.notifyUpdate ||
      (({ status, error }) =>
        status === 200 || status === 201
          ? this.$EventBus.$emit('show-alert', {
            message: 'Update OK',
            type: 'success'
          })
          : this.$EventBus.$emit('show-alert', {
            message: "Update Error " + error.toString(),
            type: 'error',
          }))
    this.notifyDelete =
      this.$attrs.notifyDelete ||
      (({ status, error }) =>
        status === 200 || status === 201
          ? this.$EventBus.$emit('show-alert', {
            message: 'Delete OK',
            type: 'success'
          })
          : this.$EventBus.$emit('show-alert', {
            message: "Delete Error " + error.toString(),
            type: 'error',
          }))
    this.notifyExport =
      this.$attrs.notifyExport ||
      (({ status, error }) => {
        if (!(status === 200 || status === 201))
          this.$EventBus.$emit('show-alert', {
            message: "Export Error " + error.toString(),
            type: 'error',
          });
      });
    this.notifyFind =
      this.$attrs.notifyFind ||
      (({ status, error }) => {
        if (!(status === 200 || status === 201))
          this.$EventBus.$emit('show-alert', {
            message: "Find Error " + error.toString(),
            type: 'error',
          });
      });
    this.notifyFindOne =
      this.$attrs.notifyFindOne ||
      (({ status, error }) => {
        if (!(status === 200 || status === 201))
          this.$EventBus.$emit('show-alert', {
            message: "FindOne Error " + error.toString(),
            type: 'error',
          });
      });
    // confirmations - do this part after getting inline status
    this.confirmCreate = 
      this.$attrs.confirmCreate || 
      (async () => {
                if (this.inline.create) {
                  const ok = await this.$refs.confirmDialogue.show({
                      title: 'Confirm',
                      message: this.$t('vueCrudX.confirm'),
                      okButton: 'Confirm',
                      cancelButton: 'Cancel',
                      })
                  if (ok) {
                    return true
                  } else {
                    return false
                  }
                } else {return true}
      }) // default only confirm if inline create
    this.confirmUpdate = this.$attrs.confirmUpdate || (() => true) // default always no need confirmation
    this.confirmDelete = 
      this.$attrs.confirmDelete || 
      (async () => {
              const ok = await this.$refs.confirmDialogue.show({
                  title: 'Confirm',
                  message: this.$t('vueCrudX.confirm'),
                  okButton: 'Confirm',
                  cancelButton: 'Cancel',
                  })
              if (ok) {
                return true
              } else {
                return false
              }
             }
          ) // default always need confirmation
    // non-ui reactive data - END
  },
  async mounted() {
    // not needed in data() because it does not exist in template, an optimization which should be done for others as well
    this.$store.commit("setSearchTerm", "");
    if (typeof this.$t !== "function") this.$t = text => text; // if no internationalization
    if (this.refreshMs > 0 && this.showForm === false) {
      // should table refresh?
      let timer = setInterval(() => {
        if (!this.showForm && !this.showFilter) this.onFilter();
      }, this.refreshMs);
      this.$once("hook:beforeDestroy", () => {
        if (timer) clearInterval(timer);
      });
    }

    this.$root.$on("searched from admin dash", arg1 => {
      this.submitSearch(); // Loop: getRecords triggers 'searched from admin'
    });

    this.$root.$on("reloadMuster", arg1 => {
      this.submitSearch();
    });

    await this.getFacilities();
    await this.getRecordsOnLoad();

    var UserObj = JSON.parse(localStorage.session);
    if (
      localStorage.savedSearchActive == null &&
      typeof localStorage.savedSearchActive != "undefined"
    ) {
      console.log("Saved Search detected!");
    }

    UserObj.dashPicker1 = this.picker;
    UserObj.dashPicker2 = this.picker2;
    UserObj.dashSingleDayPicker = this.singleDayPicker;
    localStorage.setItem("session", JSON.stringify(UserObj));
  },
  computed: {
    ...mapGetters({
      searchTerm: "getSearchTerm"
    }),
    showTitle() {
      return this.title || "";
    },
    activeCols: function() {
      return this.vtable.headers.filter(function(u) {
        return u.active == 1;
      });
    }
  },
  watch: {
    selectedFacility: async function(newVal, oldVal) {
      const response = await http.get(`/api/v2/facilityAccessLogs/facilities/${newVal}/terminals`)
      this.facilityTerminals = response.data.map((el)=>{
        return {
                text: el.TerminalNumber,
                val: el.Id
                }
      });
      this.facilityTerminals.push('All')
      this.selectedTerminal = 'All'
    },
    pagination: async function(newValue, oldValue) {
      await this.getRecords({ mode: "pagesort" });
    },
    picker: async function(newValue, oldValue) {
      let d = JSON.parse(localStorage.session);
      d.dashPicker1 = newValue;
      localStorage.setItem("session", JSON.stringify(d));
    },
    picker2: async function(newValue, oldValue) {
      let d = JSON.parse(localStorage.session);
      d.dashPicker2 = newValue;
      localStorage.setItem("session", JSON.stringify(d));
    },
    singleDayPicker: async function(newValue, oldValue) {
      let d = JSON.parse(localStorage.session);
      d.dashSingleDayPicker = newValue;
      localStorage.setItem("session", JSON.stringify(d));
    },
    loader() {
      const l = this.saveLoader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 1000);

      this.saveLoader = null;
    }
  },
  methods: {
    updateHeaders: async function() {
      try {
        const sessionUserObj = await JSON.parse(localStorage.session);
        const sessionUserId = await parseInt(sessionUserObj.id);

        const { data } = await http.post("/api/savedheader", {
          userID: sessionUserId,
          jsonData: JSON.stringify(this.vtable.headers)
        });

        // There appears to be a bug with sharing the setHeaders
        // object from localStorage
        // If you save the headers on one page, then it makes
        // other pages grids not load.
        // Below makes it so that headers from this screen are not
        // saved to local storage.

        // this.$store.commit("setHeaders", this.vtable.headers);

        setTimeout(() => (this.myIcon = "save"), 1000);
        return { status: 201, data };
      } catch (e) {
        return {
          status: e.response.status,
          error: e.toString()
        };
      }
    },
    saveSearchData: async function() {
      try {
        const { data } = await http.post(
          "/api/savedsearch",
          this.savedDataActive
        );

        setTimeout(() => (this.myIcon = "save"), 1000);
        return { status: 201, data };
      } catch (e) {
        return {
          status: e.response.status,
          error: e.toString()
        };
      }
    },
    setActiveCols: function(d, item) {
      this.updateHeaders();
      let n = 1;
      if (item.active == 1) n = 0;

      this.vtable.headers[parseInt(d)].active = parseInt(n);
    },
    setActiveFacility: function(d) {
      this.$store.commit("changeActiveFacility", d);
      this.selectedFacility = JSON.stringify(d) == 'null' ? 0 : JSON.stringify(d);
      this.totalRecordsFacilityName = "ALL facilities";
      this.getRecords({ mode: "filter-paged" });
    },

    getFacilities: async function() {
      // Lookup facilities from the access logs that have ever
      // historically used the Direction column, and the 
      // user has readAccessLogs permission

      try {
        const biDirectionalFacilities = await http.get("/api/v2/muster/facilities/?facilityId=0");
        this.allFacilities = biDirectionalFacilities.data.map((el) => {
          return { id: el.Id, Name: el.Name }
        });
      } catch (error) {
        console.log(error);
      }
    },
 
    openModal: function (item) {

      if (this.$store.state.permissionsByFacility.ReadMusterExceptions.indexOf(item.FacilityId) > -1) {
        this.$refs.exceptionsModal.dialog = true;
        this.$refs.exceptionsModal.form = {
          name: item.Name,
          entryDateTime: item.EntryTime,
          exitDateTime: item.ExitTime,
          notes: item.ExceptionNote,
          exceptionId: item.ExceptionId,
          ingressId: item.IngressId,
          egressId: item.EgressId,
          exceptionType: item.ExceptionType,
          exceptionDescription: item.ExceptionDescription,
          facilityId: item.FacilityId,
        }
      }

    },
    adminFacilityInfo: function(facility) {
      this.$store.commit("setCurrentAdminFacilityId", facility.id);
      localStorage.setItem("currentAdminFacilityId", facility.id);
      router.push(`/admin-facilities/facility/${facility.Id}`);
    },
    cancelSearch: function() {
      this.totalRecordsText = "Passed and Failed";
      this.totalRecordsTextScreen = "Yes and No";
      this.isFailed = false;
      this.isPassed = false;
      this.isNo = false;
      this.isYes = false;
      this.toggle_status = 1;
      this.toggle_screen = 1;
      this.toggle_range = 0;
      this.totalRecordsTextRange = "Last 7 days";
      this.totalRecordsFacilityName = "ALL";
      this.toggle_facilities = 0;
      this.selectedFacility = "";

      this.$root.$emit("cleared from dashboard", "cleared from dashboard");
      this.getRecords({ mode: "created" });
    },
    submitSearch: function() {
      // set Range
      let r = "";
      if (this.toggle_range == 0) r = "Last 7 days";
      else if (this.toggle_range == 1) r = "Last 30 days";
      else if (this.toggle_range == 2) r = "Last 90 days";
      else if (this.toggle_range == 3) r = "Last 365 days";
      else if (this.toggle_range == 4) {
        r =
          "Range: " +
          moment(this.picker).format("MM/DD/YYYY") +
          " - " +
          moment(this.picker2).format("MM/DD/YYYY");
      }

      this.totalRecordsTextRange = r;

      this.getRecords({ mode: "filter-paged" });
    },
    clickAutoReload: function() {
      if (this.loading2 == false) {
        this.loading2 = true;
        this.triggerAutoReload();
      } else {
        this.loading2 = false;
        clearTimeout(this.intervalid1);
      }
    },
    triggerAutoReload: function() {
      this.$refs.submitSearchButton.$el.click();
      this.intervalid1 = setInterval(() => {
        try {
          this.$refs.submitSearchButton.$el.click();
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    },
    goBack() {
      this.$router.back();
    },
    async getRecords({ mode }) {
      if (localStorage.savedSearchActive) {
        let searchData = JSON.parse(localStorage.savedSearchActive);

        if (typeof searchData !== "undefined" && searchData != null) {

          this.toggle_range = parseInt(searchData.searchRange);
          this.toggle_exceptionStatus = searchData.searchExceptionStatus;

          let r = "";
          if (this.toggle_range == 0) r = "Last 7 days";
          else if (this.toggle_range == 1) r = "Last 30 days";
          else if (this.toggle_range == 2) r = "Last 90 days";
          else if (this.toggle_range == 3) r = "Last 365 days";
          else if (this.toggle_range == 4) {
            const date1 = searchData.searchRangeStart;
            const date2 = searchData.searchRangeEnd;

            let d = await JSON.parse(localStorage.session);
            d.dashPicker1 = date1;
            d.dashPicker2 = date2;
            localStorage.setItem("session", JSON.stringify(d));

            this.picker = await date1;
            this.picker2 = await date2;

            r =
              "Range: " +
              moment(this.picker).format("MM/DD/YYYY") +
              " - " +
              moment(this.picker2).format("MM/DD/YYYY");
          }
          

          this.totalRecordsTextRange = r;

          this.toggle_facilities = 0;
          const isOurFac = element =>
            element.name == this.totalRecordsFacilityName;
          const indexOfFac = this.facilitynames.findIndex(isOurFac);
          if (indexOfFac != -1) {
            this.toggle_facilities = indexOfFac;
          }

          let d = JSON.parse(localStorage.session);
          d.topSearchDashboard = searchData.searchValue;
          localStorage.setItem("session", JSON.stringify(d));

          this.$root.$emit("set search from dash", searchData.searchValue);
        }
        localStorage.setItem("savedSearchActive", null);
      }

      if (this.infinite) {
        if (mode === "load-more") this.pagination.page = this.cursor;
        else if (
          mode === "filter-infinite" ||
          mode === "created" ||
          mode === "deleted"
        )
          this.pagination.page = this.pageDefaults.start;
        else if (
          mode === "pagesort" &&
          this.pagination.page === this.pageDefaults.start
        ) {
          console.log("ok");
        } else return;
      }
      let filters = {};

      filters["exceptionStatus"] = this.toggle_exceptionStatus;
      filters["dateRange"] = parseInt(this.toggle_range);
      filters["facilityId"] = this.selectedFacility;
      filters["terminalFilter"] = this.selectedTerminal;

      for (let key in this.filters) {
        let value = this.filters[key].value;
        if (value) filters[key] = value;
      }
      let sorters = "";
      const { sortBy = [], sortDesc = [] } = this.pagination;
      for (let index in sortBy) {
        if (sortDesc[index] !== undefined) {
          if (sorters) sorters += ";";
          sorters += `${sortBy[index]},${sortDesc[index] ? "desc" : "asc"}`;
        }
      }
      // VARIATION End - Vuetify2
      const payload = {
        parentId: this.parentId,
        pagination: this.pagination,
        filters,
        sorters
      };
      this.loading = true;
      const { status = 500, data = null, error = null } = await this.crud.find(
        payload
      ); // pagination returns for infinite scroll
      if (status === 200) {
        let { records, totalRecords = 0, cursor = "" } = data;
        this.cursor = cursor;

        if (this.infinite && this.pagination.page !== this.pageDefaults.start) {
          this.totalRecords += records.length;
          this.records = this.records.concat(records);
        } else {
          this.totalRecords = totalRecords;
          this.records = records;
        }
      }
      this.notifyFind({ status, error });
      this.loading = false;
    },
    async getRecordsOnLoad() {
      let filters = {};

      filters["exceptionStatus"] = this.toggle_exceptionStatus;
      filters["dateRange"] = 3;
      filters["facilityId"] = this.selectedFacility;
      filters["terminalFilter"] = this.selectedTerminal;

      for (let key in this.filters) {
        let value = this.filters[key].value;
        if (value) filters[key] = value;
      }
      let sorters = "";
      const { sortBy = [], sortDesc = [] } = this.pagination;
      for (let index in sortBy) {
        if (sortDesc[index] !== undefined) {
          if (sorters) sorters += ";";
          sorters += `${sortBy[index]},${sortDesc[index] ? "desc" : "asc"}`;
        }
      }

      const payload = {
      parentId: this.parentId,
      pagination: this.pagination,
      filters,
      sorters
      };

      const res = await this.crud.find(payload)
      this.records = res.data.records
      this.totalRecords = res.data.totalRecords
    },
    async getRecord(id) {
      this.loading = true;
      const {
        status = 500,
        data = null,
        error = null
      } = await this.crud.findOne(id);
      if (status === 200 && data) {
        for (let key in this.form) {
          this.form[key].value = this.form[key].render
            ? this.form[key].render(data[key])
            : data[key];
        }
      }
      this.notifyFindOne({ status, error });
      this.loading = false;
      this.getTotalRecordsText();
    },
    async updateRecord({ record }) {
      if (!this.confirmUpdate()) return;
      this.loading = true;
      // eslint-disable-next-line
      const {
        status = 500,
        data = null,
        error = null
      } = await this.crud.update({ record });
      this.loading = false;
      if (status === 200) await this.updated({ data });
      this.notifyUpdate({ status, error });
    },
    async createRecord({ record, parentId }) {
      if (!this.confirmCreate()) return;
      this.loading = true;
      // eslint-disable-next-line
      const {
        status = 500,
        data = null,
        error = null
      } = await this.crud.create({ record, parentId });
      this.loading = false;
      if (status === 201) await this.created({ data });
      this.notifyCreate({ status, error });
    },
    async deleteRecord(id) {
      if (!this.confirmDelete()) return;
      this.loading = true;
      // eslint-disable-next-line
      const {
        status = 500,
        data = null,
        error = null
      } = await this.crud.delete(id);
      if (status === 200) await this.deleted(id);
      this.loading = false;
      this.notifyDelete({ status, error });
    },
    async exportRecords() {
      this.loading = true;
      // VARIATION Start - Vuetify2
      let filters = {};

      filters["exceptionStatus"] = this.toggle_exceptionStatus;
      filters["dateRange"] = parseInt(this.toggle_range);
      filters["facilityId"] = this.selectedFacility;
      filters["terminalFilter"] = this.selectedTerminal;

      for (let key in this.filters) {
        let value = this.filters[key].value;
        if (value) filters[key] = value;
      }
      // VARIATION End - Vuetify2
      const payload = {
        parentId: this.parentId,
        pagination: this.pagination, // not used
        filters,
        sorters: ""
      };
      this.loading = true;
      const { status = 500, error = null } = await this.crud.export(payload);
      this.notifyExport({ status, error });
      this.loading = false;
    },
    formClose() {
      this.showForm = false;
      this.$emit("form-close");
    },
    async formOpen(id) {
      this.selectedId = id;
      if (id) {
        await this.getRecord(id); // update
      } else {
        // create - set initial data
        this.editingRow = null;
        for (let key in this.form)
          this.form[key].value = this.$attrs.form[key].default || "";
      }
      this.showForm = true;
      this.$emit("form-open", this.form);
    },
    async formSave(e) {
      let record = {};
      let id = this.selectedId;
      for (let key in this.form) {
        if (key !== this.idName) record[key] = this.form[key].value; // ignore id, get from selectedId
      }
      record[this.idName] = id;
      if (id) await this.updateRecord({ record });
      else await this.createRecord({ record, parentId: this.parentId });
      this.formClose();
    },
    async formDelete(e) {
      const id = this.selectedId;
      if (id) await this.deleteRecord(id);
      this.formClose();
    },
    async onFilter() {
      if (this.editingRow) this.editingRow = null;
      this.totalRecords = 0;
      this.records = [];
      if (this.infinite) {
        // VARIATION Start Vuetify2
        // NO NEED... IT IS ALREADY DONE this.pagination.page = this.pageDefaults.start // this does not fire off pagination event
        await this.getRecords({ mode: "filter-infinite" }); // so need to call this after
        // VARIATION End Vuetify2
      } else {
        // VARIATION Start Vuetify2
        if (this.pagination.page !== this.pageDefaults.start) {
          this.pagination = {
            // this fire off pagination event
            ...this.pagination,
            page: this.pageDefaults.start
          };
        } else {
          await this.getRecords({ mode: "filter-paged" });
        }
        // VARIATION End Vuetify2
      }
    },
    async onExport() {
      await this.exportRecords();
    },
    // clearFilter () { this.$refs.searchForm.reset() }, // can do test code here too
    onInput(ev, key) {
      this.$emit(`${key}Input`, ev);
    },

    // INLINE EDIT START
    _isRowEditing(item) {
      return !this.editingRow
        ? false
        : item[this.idName] === this.editingRow[this.idName];
    },
    async _inlineSave(item) {
      item = { ...this.editingRow };
      this.editingRow = null;
      await this.updateRecord({ record: item });
    },
    async _inlineCreate() {
      this.editingRow = null;
      await this.createRecord({ record: {}, parentId: this.parentId });
    },

    // INLINE EDIT END
    _isHidden(hidden) {
      return (
        (hidden === "add" && !this.selectedId) ||
        (hidden === "edit" && !!this.selectedId) ||
        hidden === "all"
      );
    },
    _isReadOnly(readonly) {
      return (
        (readonly === "add" && !this.selectedId) ||
        (readonly === "edit" && !!this.selectedId) ||
        readonly === "all"
      );
    },
    _isObject(obj) {
      return obj !== null && typeof obj === "object";
    }
  }
};
</script>
<style type="text/css" scope>
.savesearch .v-input__slot {
  margin-bottom: 0 !important;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #006fba;
  color: #ffffff !important;
  box-shadow: none;
  padding: 0.65rem 0.25rem !important;
}

.failed-status>.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #d63b2f;
  color: #ffffff !important;
  box-shadow: none;
  padding: 0.65rem 0.25rem !important;
}

.pass-status>.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #39b54a;
  color: #ffffff !important;
  box-shadow: none;
  padding: 0.65rem 0.25rem !important;
}

.datagrid-status {
  font-size: 0.85rem;
  padding-left: 0.5rem;
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.dashtoolbar-col {
  display: flex;
}

.btn-toggle {
  flex-direction: column;
}

.v-btn.search-toolbuttons {
  margin-left: 5px;
  background-color: #ffffff;
  border-color: #006fba;
  border-width: 1px;
  color: #006fba;
  margin-bottom: 5px;
}

.v-btn.search-toolbuttons.v-btn.v-btn--depressed::before {
  background-color: #ffffff;
  background: #ffffff;
  border-color: #006fba !important;
  color: #ffffff !important;
  opacity: 1;
}

.v-btn.search-toolbuttons.active {
  background: #39b54a;
  background-color: #39b54a !important;
  border-color: #39b54a;
  color: #ffffff;
}

.v-btn.search-toolbuttons.v-btn.v-btn--depressed::before {
  background-color: #ffffff;
  background: #ffffff;
  border-color: #006fba !important;
  color: #ffffff !important;
  opacity: 1;
}

.v-btn.search-toolbuttons.v-btn.v-item--active::before {
  background-color: #39b54a;
  background: #39b54a;
  border-color: #39b54a;
  color: #ffffff !important;
  opacity: 1;
}

.v-btn.search-toolbuttons.v-btn.v-item--active span {
  color: #ffffff !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .search-toolbuttons.v-btn.v-btn {
  border-color: #006fba !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .search-toolbuttons.v-btn.v-btn.v-item--active {
  border-color: #39b54a !important;
}

.v-btn.search-toolbuttons.v-btn.v-btn--depressed.popup::before {
  background-color: #ffffff;
  background: #ffffff;
  border-color: #006fba !important;
  color: #ffffff !important;
  border-width: 1px !important;
  opacity: 1;
}

.dashswitch {
  height: 20px;
  padding-left: 15px;
}

.searchswitch {
  height: 20px;
  padding-left: 15px;
}

.saveswitch {
  height: 20px;
  padding-left: 5px;
}

h1.dashtitle {
  font-weight: normal;
  color: white;
}

h1.dashtitle span {
  font-weight: normal;
  color: #006fba;
}

.dashtoolbar {
  padding-right: 0;
}

a.dashlinks {
  color: #53595e;
  text-decoration: none;
}

.dashlinks.active {
  color: white;
  text-decoration: underline;
}

.dashswitch .v-input--switch__track {
  background-color: #ffffff;
  pointer-events: none;
  transition: inherit;
}

.dashswitch .v-input--switch__thumb {
  background-color: #39b54a;
  pointer-events: none;
  transition: inherit;
}

.searchswitch .v-input--switch__track {
  color: #39b54a !important;
  caret-color: #39b54a !important;
  opacity: 1;
}

.searchswitch .v-input--switch__track.red--text {
  color: #ff0000 !important;
  caret-color: #ffffff !important;
  opacity: 1;
}

.v-application .red--text.v-input--switch__thumb {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  opacity: 1;
}

.v-application .green--text {
  color: #39b54a !important;
  caret-color: #39b54a !important;
  opacity: 1;
}

.searchswitch .v-input--switch__track.grey--text {
  color: #e6e6e6 !important;
  caret-color: #e6e6e6 !important;
  opacity: 1;
}

.theme--light.v-input--switch .v-input--switch__track {
  color: #e6e6e6 !important;
  caret-color: #e6e6e6 !important;
  opacity: 1;
}

.theme--light.v-input--switch .v-input--switch__thumb {
  color: #39b54a !important;
}

.v-application .grey--text {
  color: #e6e6e6 !important;
  caret-color: #e6e6e6 !important;
  opacity: 1;
}

.v-application .grey--text.v-input--switch__thumb {
  color: #39b54a !important;
  caret-color: #39b54a !important;
  opacity: 1;
}

.v-input--switch--inset .v-input--switch__track {
  border-radius: 14px;
  height: 28px;
  left: -4px;
  opacity: 1;
  top: calc(50% - 14px);
}

.theme--light.v-card {
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}

.no-toppad {
  padding-top: 0;
}

header.dashtoolbar-wrapper .v-toolbar__content {
  /* max-width: 1770px; */
  padding-right: 25px !important;
  padding-left: 0 !important;
}

div.v-card__title.headline {
  font-size: 1rem !important;
  color: #ffffff;
  font-weight: bold;
  line-height: 0.5rem;
}

@media (max-width: 960px) {
  header.dashtoolbar-wrapper .v-toolbar__content .spacer {
    flex-grow: 0 !important;
  }
}

.search-button {
  margin-left: 10px;
  opacity: 0.6;
}

.search-button:hover {
  opacity: 1;
}

.cancel-button {
  margin-left: 5px;
  opacity: 0.6;
  color: #666666;
}

.cancel-button:hover {
  opacity: 1;
}

@media (min-width: 960px) {
  .container {
    max-width: 98%;
  }
}

@media (min-width: 1470px) {
  .container {
    max-width: 98%;
  }
}

/*@media (min-width: 1470px) {
  .col-lg-2 {
    flex: 0 0 17%;
    max-width: 17%;
  }
}*/

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: transparent;
}

button.searchlinks.red {
  background-color: #ffffff !important;
  color: #f44336 !important;
}

button.searchlinks.red.v-btn--active {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #ffffff !important;
}

button.searchlinks.green {
  background-color: #ffffff !important;
  color: #39b54a !important;
}

button.searchlinks.green.v-btn--active {
  background-color: #39b54a !important;
  border-color: #39b54a !important;
  color: #ffffff !important;
}

button.searchlinks.primary {
  background-color: #ffffff !important;
  color: #006fba !important;
}

button.searchlinks.primary.v-btn--active {
  background-color: #006fba !important;
  border-color: #006fba !important;
  color: #ffffff !important;
}

header.dashtoolbar-wrapper .v-toolbar__content {
  /* max-width: 1770px; */
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.toolbar-padd {
  margin-top: 0.5rem;
}

.v-btn.v-size--default {
  font-size: 0.775rem;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 !important;
}

.v-application .grey.lighten-3 {
  background-color: transparent !important;
  border-color: transparent !important;
}

.container.no-padd {
  padding-top: 0 !important;
  max-width: 100%;
  overflow-x: hidden;
  padding-bottom: 0;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 0 8px;
}

.v-data-table__wrapper {
  padding: 0.5rem;
}

div.more-wrapper {
  padding-bottom: 1rem;
}

a.green-link {
  font-size: 0.785rem;
  color: #39b54a !important;
  text-decoration: underline;
  font-weight: bold;
}

a.green-link:hover {
  color: #39b54a !important;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}

.v-messages {
  min-height: 0 !important;
}

.customloader {
  animation: loader 1s infinite;
  display: flex;
  background-color: #237dba !important;
}

@media (min-width: 1265px) {
  .passFail {
    max-width: 190px;
  }

  .screenYesNo {
    max-width: 260px;
  }

  .toggleFacilities {
    max-width: 290px;
    margin-left: 45px;
  }

  .dateToggle {
    margin-right: 25px;
  }
}

@media (min-width: 1340px) {
  .toggleFacilities {
    margin-left: 15px;
  }
}

@media (min-width: 1264px) and (max-width: 1450px) {
  .searchReset {
    min-width: 170px;
    /* margin-left: 20px; */
    margin-right: 5px;
  }
}

@media (min-width: 1451px) {
  .searchReset {
    min-width: 170px;
    margin-left: 10px;
  }
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

</style>

import { http } from '@/axios';
import router from '../router';
import { EventBus } from '../event-bus';

const USE_OTP = false;

export default {
  async setAccessLogHeaders({ commit }, payload) {
    // console.log(payload)
    let rv = null;

    try {
      // what to call here:
      rv = await http.get(`/api/savedheader/${payload.id}`);
    } catch (e) {
      console.log(e);
      // commit('setDefaultHeaders',e)
    }
    if (rv) {
      if (rv.data != '') {
        commit('setHeaders', JSON.parse(rv.data));
      } else {
        commit('setDefaultHeaders', payload);
      }
    } else {
      commit('setDefaultHeaders', payload);
    }
  },

  async forgotPassword({ commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    const { email } = payload;
    let rv = null;
    try {
      rv = await http.get(`/api/users/checkemail/${email}`);
    } catch (e) {
      console.log(e);
    }

    if (rv) {
      commit('setError', {
        message: 'Check your email for reset password link',
      });
      EventBus.$emit('show-alert', {
        message: `If your email address is valid, an email containing instructions to reset your password has been sent to ${email}. Please check your inbox and follow the provided steps to reset your password. If you don't see the email in your inbox, kindly check your spam folder as well.`,
        type: 'info',
        enableTimeout: false,
      });
      if (router.currentRoute.name === 'forgot') {
        router.push('/');
      }
      // Send the confirmation email
      // Which should send --> await router.push('/ConfirmForgot')
    } else {
      commit('setError', {
        message: 'Check your email for reset password link',
      });
    }
    commit('setLoading', false);
  },

  async resetPassword({ commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    const { token, password, confirm_password } = payload;
    let rv = null;

    if (password != confirm_password) {
      commit('setError', { message: 'Passwords do not match. ' });
    } else {
      // token is invalid?
      try {
        rv = await http.post(`/api/users/checktoken/token/${token}`, {
          pass: password,
        });
      } catch (e) {
        console.log(e);
      }

      if (rv) {
        commit('setError', { message: 'Password changed, sign in. ' });
        if (router.app.$route.path !== '/') {
          router.push('/');
        }
      } else {
        commit('setError', {
          message: 'Failed to reset password, try again later. ',
        });
        if (router.app.$route.path !== '/') {
          router.push('/');
        }
      }
    }
    commit('setLoading', false);
  },

  async signUserUp({ commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    let rv = null;
    const { email, password } = payload;
    try {
      rv = await http.post('/api/auth/signup', { email, password });
    } catch (e) {}
    commit('setLoading', false);
    if (rv) {
      const newUser = { id: payload.id, email: payload.email };
      commit('setUser', newUser);
      commit('setError', { message: 'User Registered' });
    } else {
      commit('setError', { message: 'Error Signup' });
    }
  },
  async signUserIn({ dispatch, commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    let rv = null;
    const { Username, password } = payload;
    try {
      rv = await http.post('/api/auth/login', { Username, password });

      const { data } = rv;
      await dispatch('autoSignIn', data); // token
      await dispatch('setAccessLogHeaders', data);
      await router.push('/systemnotice');
    } catch (e) {
      commit('setError', { message: e.response.data.message });
    }
    commit('setLoading', false);
  },
  async verifyOtp({ dispatch, commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    let rv = null;
    const { pin } = payload;
    try {
      rv = await http.post('/api/auth/otp', { pin });
      const { data } = rv;
      await dispatch('autoVerify', data); // token
      // console.log('data',data)
      await router.push('/dashboard');
    } catch (e) {
      console.log('Currently Nothing To Handle Failures e.g. back to login / retries', e);
    }
    if (!rv) {
      commit('setError', { message: 'Verify Error' });
    }
    commit('setLoading', false);
  },

  // TBD fix broken promises here... actions return a promise...
  // layout-secure, layout-public, setSecure payload is route instead of layout name..., setPublic
  autoSignIn({ commit }, payload) {
    // payload.token only
    commit('setUser', payload);
    commit('setLayout', 'layout-loggedIn');
  },
  async autoVerify({ commit }, payload) {
    // payload.token only
    commit('setUser', payload);
    commit('setLayout', 'layout-loggedIn');
  },
  clearError({ commit }) {
    commit('setError', null);
  },

  setNetworkError({ commit }, payload) {
    commit('mutateNetworkError', payload);
  },
  routeToUnauthorizedPage() {
    router.push('/unauthorized');
  },
  // Common Logout
  async logout({ commit }, payload) {
    commit('setLoading', true);
    localStorage.removeItem('session');
    console.log('logging out', payload);
    if (payload.forced) {
      // auth failure detected
    } else {
      // logout button clicked
      try {
        await http.get('/api/auth/logout');
      } catch (e) {
        if (!e.response || e.response.status === 401) {
          // server or authorization error
          // ok please continue
        } else {
          return; // may have problems here... loading still true, etc...
        }
      }
    }
    if (payload.forced) {
      commit('setError', { message: 'Session Expired' });
    }
    if (router.app.$route.path !== '/') {
      await router.push('/');
    }

    commit('setUser', null);
    commit('setLayout', 'layout-default');
    commit('setLoading', false);
    commit('setAgreeToNotice', false);
    commit('changeActiveFacility', null);
    commit('setSearchTerm', null);
  },
};

// import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store'
// import jwtDecode from 'jwt-decode'
import { API_URL, HTTPONLY_TOKEN, WITH_CREDENTIALS } from '@/config'

// jQuery 1.5.1 xhrFields: {withCredentials: true}
// ES6 fetch() credentials: 'include'
// axios: withCredentials: true

export const http = axios.create({
  withCredentials: WITH_CREDENTIALS,
  // xsrfCookieName: 'csrftoken_testtest',
  // xsrfHeaderName: 'X-CSRFToken', 
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 5000000
})

http.interceptors.request.use((config) => {
  // Do something before request is sent if needed
  const myURL = new URL(config.baseURL + config.url)
  if (myURL.pathname === '/api/auth/logout') config.headers['refresh_token'] = store.state.user.refresh_token // add refresh token for logout
  return config
}, (error) => {
  // Do something with request error if needed
  return Promise.reject(error)
})

// Add a response interceptor
http.interceptors.response.use(
  (response) => response, // Do something with response data if needed
  (error) => { // Do something with response error
    if (error.config && error.config.baseURL && error.config.url) {
      const myURL = new URL(error.config.baseURL + error.config.url)
      if (error.response && error.response.status === 401) { // auth failed
        if (myURL.pathname !== '/api/auth/logout' && myURL.pathname !== '/api/auth/otp') {
          if (error.response.data.message === 'Token Expired Error') {
            store.dispatch('routeToUnauthorizedPage')
            store.dispatch('logout', { forced: true })
          } 
          if (error.response.data.e === 'Not Authorized to access the requested resource'){
            // Route to Unauthorized Page
            store.dispatch('routeToUnauthorizedPage')
            return Promise.reject(error)
          } else {
            store.dispatch('logout', { forced: true })
            return Promise.reject(error)
          }
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    }
  }
)

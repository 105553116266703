<template>
  <transition name="fade">
    <v-dialog v-model="isVisible" persistent width="unset">
      <div v-if="isVisible" class="popup-modal">
        <div class="window">
          <slot />
        </div>
      </div>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  name: 'PopupModal',

  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>
